import * as React from "react"
import useConstant from "@digits-shared/hooks/useConstant"
import styled from "styled-components"
import { v4 as generateUUID } from "uuid"

const SVGContainer = styled.svg<{ gradientId: string }>`
  path {
    fill: url(#${({ gradientId }) => gradientId});
  }
`

export const SvgAIFillGradient: React.FC<{
  className?: string
  IconComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>
}> = ({ className, IconComponent }) => {
  const gradientId = useConstant(() => `svg_gradient_${generateUUID()}`)

  return (
    <SVGContainer
      gradientId={gradientId}
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id={gradientId}
          x1="2.51869"
          y1="24.3981"
          x2="20.3187"
          y2="22.6469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#399CD4" />
          <stop offset="1" stopColor="#5E5BE9" />
        </linearGradient>
      </defs>
      <IconComponent />
    </SVGContainer>
  )
}
